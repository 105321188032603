@import "./mediaquery";
@import "./variables.scss";

.workplan {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .container {
    border-radius: 10px;

    .topbar {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;

      .new {
        display: grid;
        grid-template-columns: auto auto;
        gap: 5px;
        font-size: small;
        border-radius: 5px;
        padding: 10px;
        background-color: white;
        width: fit-content;
        height: fit-content;
        cursor: pointer;
      }
      .new:hover {
        background-color: #e5e5e5;
      }

      .top {
        display: grid;
        grid-template-columns: repeat(6, auto);
        gap: 10px;
        align-items: center;
        width: fit-content;
        margin: 0 0 1em auto;

        // .fa-icn{
        //   background-color: white;
        //   height: 100%;
        //   width: auto;
        // }
        .fa-icon {
          font-size: small;
          border-radius: 5px;
          padding: 8px;
          color: $secondary;
          background-color: white;
          cursor: pointer;
          text-align: center;
          width: 32px;
          min-height: 32px;
          height: 100%;
          line-height: 100% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .topitem {
          font-size: small;
          border-radius: 5px;
          padding: 10px;
          background-color: white;
          cursor: pointer;
          text-align: center;
          width: fit-content;
          height: 100%;
          line-height: 100% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .fa-icon:hover,
        .topitem:hover {
          background-color: #e5e5e5;
        }
        .active {
          font-size: small;
          border-radius: 5px;
          padding: 10px;
          background-color: #e5e5e5;
        }
      }
    }

    .dailywp {
      .month {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 10px;
        margin-bottom: 1em;

        h4 {
          font-size: small;
          height: 100%;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .select {
          margin: 0 0 0 0;

          label {
            display: block;
            margin: 0 0 0 0;
            font-weight: 400;
            font-size: small;
          }

          select {
            margin: 0px 0 0 0;
            padding: 5px;
            width: 100%;
            background-color: none !important;
            border: 1px solid #60606030;
            outline: none;
            font-size: small !important;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
      }
      .dates {
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        gap: 10px;

        .fa-angle-right {
          margin-left: auto;
        }

        .fa-icon {
          font-size: small;
          border-radius: 5px;
          padding: 8px;
          color: $secondary;
          background-color: white;
          cursor: pointer;
          text-align: center;
          width: 32px;
          min-height: 32px;
          height: 100%;
          line-height: 100% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dateitem {
          font-size: small;
          border-radius: 5px;
          padding: 5px;
          background-color: white;
          cursor: pointer;
          text-align: center;

          h4 {
            margin-top: 5px;
            font-weight: 560;
            color: gray;
          }
        }
        .fa:hover,
        .dateitem:hover {
          background-color: #e5e5e5;
        }
        .active {
          font-size: small;
          border-radius: 5px;
          padding: 5px;
          background-color: #e5e5e5;
          text-align: center;

          h4 {
            margin-top: 5px;
            font-weight: 560;
            color: gray;
          }
        }
      }

      .dlist {
        background-color: white;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding: 1em;
        margin-top: 1em;
        border-radius: 5px;
        min-height: 70vh;

        .soitem {
          box-shadow: 1px 1px 5px #60606010;
          border-radius: 5px;
          border: 1px solid #60606010;
          padding: 1em;
          height: fit-content;
          font-size: small;
          position: relative;

          .update {
            position: absolute;
            right: 1em;
            top: 10px;
            display: flex;
            gap: 10px;

            .pencil {
              background-color: $primarylight;
              border-radius: 2px;
              padding: 5px;
              cursor: pointer;
            }
            .delete {
              background-color: $secondary;
            }
            .pencil:hover {
              background-color: $primary;
            }
          }

          h4 {
            font-weight: 500;
            margin-bottom: 5px;
          }
          .div3equal {
            margin-bottom: 8px;
          }
          hr {
            margin-bottom: 5px;
            color: #60606010;
          }
          .title {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1em;
            margin-bottom: 8px;

            .left {
              h2 {
                background-color: $primarylight;
                border-radius: 5px;
                font-size: large;
                font-weight: 600;
                height: 54px;
                line-height: 54px;
                width: 54px;
                color: white;
                text-align: center;
              }

              h4 {
                margin-top: 10px;
                font-size: small;
                font-weight: 400;
                color: $primary;
                text-align: center;
              }
            }

            img {
              width: 74px;
              max-height: 74px;
              height: fit-content;
              object-fit: contain;
              object-position: top;
            }
          }

          .task {
            padding: 2px;
          }

          b {
            font-weight: 560;
          }

          p {
            margin-bottom: 2px;
            color: gray;
            font-size: x-small;
          }
        }
      }

      @include maxwidth(mobile) {
        .dates {
          width: 100% !important;
          overflow-x: auto;
        }
      }
      @include maxwidth(tablet) {
        .dates {
          width: 100% !important;
          overflow-x: auto;
        }
        .dlist {
          display: flex !important;
          flex-direction: column !important;
          gap: 1em;
        }
      }
    }

    .orgwp {
      .month {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        gap: 10px;

        h4 {
          font-size: small;
          height: 100%;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .wtop {
          display: grid;
          grid-template-columns: repeat(8, auto);
          gap: 10px;
          width: fit-content;
          // margin: 0 0 1em auto;

          .fa,
          .topitem {
            font-size: small;
            border-radius: 5px;
            padding: 10px;
            background-color: white;
            cursor: pointer;
          }

          .fa:hover,
          .topitem:hover {
            background-color: #e5e5e5;
          }

          .active {
            font-size: small;
            border-radius: 5px;
            padding: 10px;
            background-color: #e5e5e5;
          }
        }

        .select {
          margin: 0 0 0 0;

          label {
            display: block;
            margin: 0 0 0 0;
            font-weight: 400;
            font-size: small;
          }

          select {
            margin: 0 0 0 0;
            padding: 5px;
            width: 100%;
            background-color: none !important;
            border: 1px solid #60606030;
            outline: none;
            font-size: small !important;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
      }
      .dates {
        display: grid;
        grid-template-columns: repeat(16, 1fr);
        gap: 10px;

        .fa-angle-right {
          margin-left: auto;
        }

        .fa {
          font-size: small;
          border-radius: 5px;
          padding: 10px;
          background-color: white;
          cursor: pointer;
          text-align: center;
          width: fit-content;
          height: 100%;
          line-height: 100% !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .dateitem {
          font-size: small;
          border-radius: 5px;
          padding: 5px;
          background-color: white;
          cursor: pointer;
          text-align: center;

          h4 {
            margin-top: 5px;
            font-weight: 560;
            color: gray;
          }
        }
        .fa:hover,
        .dateitem:hover {
          background-color: #e5e5e5;
        }
        .active {
          font-size: small;
          border-radius: 5px;
          padding: 5px;
          background-color: #e5e5e5;
          text-align: center;

          h4 {
            margin-top: 5px;
            font-weight: 560;
            color: gray;
          }
        }
      }

      .mlist {
        background-color: white;
        padding: 1em;
        margin-top: 1em;
        border-radius: 5px;
        min-height: 70vh;

        .dow {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          padding: 10px;
          border: 1px solid #60606010;

          h4 {
            font-weight: 500;
            text-align: center;
            font-size: medium;
          }
        }

        .calendar {
          display: grid;
          grid-template-columns: repeat(7, 1fr);

          .day {
            padding: 10px;
            border: 1px solid #60606010;
            min-height: 74px;
            position: relative;

            h4 {
              font-size: small;
              margin-bottom: 5px;
              font-weight: 500;
              color: $primarylight;
            }

            hr {
              color: rgba($color: $primarylight, $alpha: 0.5);
              margin-bottom: 5px;
            }

            h6 {
              margin-left: auto;
              width: fit-content;
              font-weight: 400;
              font-size: small;
              color: #60606050;
              font-style: italic;
              margin-bottom: 10px;
            }

            h3 {
              margin-left: auto;
              text-align: center;
              font-weight: 500;
              font-size: small;
              font-style: italic;
              margin: 0 0 5px 0;
              color: $primary;
            }

            .row {
              padding: 5px;

              p {
                font-size: x-small;
              }
            }
          }
        }
      }
    }

    .sowp {
      .month {
        display: grid;
        grid-template-columns: 1fr auto auto auto;
        gap: 10px;
        margin-bottom: 1em;

        h4 {
          font-size: small;
          height: 100%;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .select {
          margin: 0 0 0 0;

          label {
            display: block;
            margin: 0 0 0 0;
            font-weight: 400;
            font-size: small;
          }

          select {
            margin: 0px 0 0 0;
            padding: 5px;
            width: 100%;
            background-color: none !important;
            border: 1px solid #60606030;
            outline: none;
            font-size: small !important;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
      }

      .dlist {
        background-color: white;
        padding: 1em;
        margin-top: 1em;
        border-radius: 5px;
        min-height: 70vh;

        .soitem {
          box-shadow: 1px 1px 5px #60606010;
          border-radius: 5px;
          border: 1px solid #60606010;
          padding: 1em;
          height: fit-content;
          font-size: small;
          margin-bottom: 1em;
          position: relative;

          .div2equal {
            gap: 0 !important;
          }

          .soindex {
            background-color: $primarylight;
            display: flex;
            align-items: center;
            color: white;
            font-size: large;
            border-radius: 10px;
            width: 60px;
            h2 {
              width: 100%;
              text-align: center;
              font-weight: 560;
            }
          }

          .status {
            position: absolute;
            right: -1em;
            top: 5px;
            background-color: $primarylight;
            border-radius: 0 5px 0 5px;
            z-index: 1;
            padding: 5px;
            color: white;
            font-size: small;
          }

          .sofficer {
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 10px;
            box-shadow: 1px 1px 5px #60606030;
            .div4equal {
              display: grid;
              grid-template-columns: auto 1fr 1fr 1fr;
              gap: 10px;

              .index {
                background-color: $primarylight;
                display: flex;
                align-items: center;
                color: white;
                font-size: large;
                border-radius: 10px;
                h2 {
                  width: 100%;
                  text-align: center;
                  font-weight: 500;
                  font-size: medium;
                  padding: 10px;
                }
              }
            }
          }
          h4 {
            font-weight: 500;
            margin-bottom: 5px;
          }
          .div3equal {
            margin-bottom: 8px;
          }
          hr {
            margin-bottom: 5px;
            color: #60606010;
          }
          .title {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 1em;
            margin-bottom: 8px;

            img {
              width: 74px;
              max-height: 74px;
              height: fit-content;
              object-fit: contain;
              object-position: top;
            }
          }

          b {
            font-weight: 560;
          }

          p {
            margin-bottom: 2px;
            color: gray;
            font-size: x-small;
          }
        }
      }
    }

    .monthwp {
      .month {
        display: grid;
        grid-template-columns: 1fr auto auto;
        gap: 10px;
        margin-bottom: 1em;

        h4 {
          font-size: small;
          height: 100%;
          display: flex;
          align-items: center;
          font-weight: 500;
        }

        .select {
          margin: 0 0 0 0;

          label {
            display: block;
            margin: 0 0 0 0;
            font-weight: 400;
            font-size: small;
          }

          select {
            margin: 0px 0 0 0;
            padding: 5px;
            width: 100%;
            background-color: none !important;
            border: 1px solid #60606030;
            outline: none;
            font-size: small !important;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }
      }

      .mlist {
        background-color: white;
        padding: 1em;
        margin-top: 1em;
        border-radius: 5px;
        min-height: 70vh;

        .dow {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          padding: 10px;
          border: 1px solid #60606010;

          h4 {
            font-weight: 500;
            text-align: center;
            font-size: medium;
          }
        }

        .calendar {
          display: grid;
          grid-template-columns: repeat(7, 1fr);

          .day {
            padding: 10px;
            border: 1px solid #60606010;
            min-height: 74px;
            position: relative;

            h4 {
              font-size: medium;
              margin-bottom: 5px;
              font-weight: 500;
              color: $primarylight;
              text-align: center;
            }

            hr {
              color: rgba($color: $primarylight, $alpha: 0.5);
              margin-bottom: 5px;
            }

            h6 {
              margin-left: auto;
              width: fit-content;
              font-weight: 400;
              font-size: small;
              color: #60606050;
              font-style: italic;
              margin-bottom: 10px;
            }

            h3 {
              margin-left: auto;
              text-align: center;
              font-weight: 500;
              font-size: small;
              font-style: italic;
              margin: 0 0 5px 0;
              color: $primary;
            }

            .row {
              padding: 5px;

              p {
                font-size: x-small;
              }
            }
          }
        }
      }
    }

    .createworkplan {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 9999;

      .cpcontainer {
        max-width: 85%;
        max-height: 90vh;
        width: 100%;
        background-color: white;
        overflow-y: auto;
        margin: auto;
        border-radius: 5px;
        box-shadow: 2px 2px 10px #60606050;

        .tp {
          padding: 5px 1em 5px 1em;
          background-color: $primarylight;
          color: white;
          display: grid;
          grid-template-columns: 1fr auto;
          border-radius: 5px 5px 0 0;

          h3 {
            font-size: medium;
            font-weight: 560;
          }

          h4 {
            font-weight: 400;
          }

          .fa-times {
            padding: 10px;
            cursor: pointer;
            display: block;
            margin: auto;
            z-index: 99;
            color: $accent;
          }
          .fa-times:hover {
            color: $accent;
          }
        }
        .bdy {
          padding: 1em;

          .section {
            border: 1px solid #60606030;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 1em;

            h3 {
              font-size: small;
              color: $primary;
            }

            .item {
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;
              padding: 10px;
              margin-top: 10px;
              div {
                display: grid;
                grid-template-columns: 1fr auto;
              }

              p {
                font-size: small !important;
              }
              .fa-times {
                margin-left: auto;
                color: $accent;
                display: block;
              }
            }

            .ind {
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;
              padding: 10px;
              margin-top: 5px;

              div {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 10px;
              }

              p {
                font-size: small !important;
              }
              .fa-times {
                margin-left: auto;
                color: $accent;
                display: block;
              }
            }

            .so_list {
              border-bottom: 1px solid #60606010;

              h2 {
                font-size: medium;
                margin-bottom: 2px;
                font-weight: 500;
              }
              h5 {
                font-size: small;
                margin-bottom: 2px;
                color: gray !important;
                font-weight: 200;
              }
            }

            .search {
              position: relative;

              .search_list {
                position: absolute;
                left: 0;
                right: 0;
                top: 74px;
                padding: 10px;
                border: 1px solid #60606030;
                border-radius: 5px;
                box-shadow: 1px 1px 5px #60606030;
                z-index: 9999;
                background-color: white;

                p {
                  font-size: small;
                  padding: 5px;
                  cursor: pointer;
                  margin-bottom: 3px;
                }
                p:hover {
                  color: $primarylight;
                }
              }
            }

            h4 {
              font-size: medium;
              margin-bottom: 10px;
              font-weight: 560;
            }

            hr {
              color: #60606010;
            }

            .input {
              margin: 10px 0 0 0;

              .pwd {
                position: relative;
                margin: 0px 0 10px 0;

                .fa-eye {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 5px;
                  color: $primarylight;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
              }

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }
              textarea {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }
              textarea:focus {
                border-bottom: 2px solid $primary;
              }
              input {
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
            .select {
              margin: 10px 0 0 0;

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }

              select {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
            }

            p {
              font-size: medium;
              margin: 0px 0 5px 0;

              b {
                color: #74465c;
                cursor: pointer;
              }
            }
          }

          h6 {
            font-size: medium;
            font-weight: 200;
            color: $accent;
            height: 32px;
            line-height: 32px;
            text-align: center;
          }
          button {
            display: block;
            margin: 1em auto 0 auto;
            padding: 10px 2em 10px 2em;
            border-radius: 5px;
            width: fit-content;
            background-color: $primary;
            color: white;
            border-top: none;
            border-right: none;
            border-left: none;
            box-shadow: 1px 1px 5px #60606030;
            cursor: pointer;
          }
          button:hover {
            background-color: rgba($color: $primary, $alpha: 0.8);
          }
        }
      }
    }

    .mdetails {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 9999;

      .cpcontainer {
        max-width: 85%;
        min-height: 50vh;
        max-height: 90vh;
        width: 100%;
        background-color: white;
        overflow-y: auto;
        margin: auto;
        border-radius: 5px;
        box-shadow: 2px 2px 10px #60606050;

        .tp {
          padding: 5px 1em 5px 1em;
          background-color: $primarylight;
          color: white;
          display: grid;
          grid-template-columns: 1fr auto;
          border-radius: 5px 5px 0 0;

          h3 {
            font-size: medium;
            font-weight: 560;
          }

          h4 {
            font-weight: 400;
          }

          .close {
            height: 24px;
            width: 24px;
            padding: 5px;
            cursor: pointer;
            display: block;
            margin: auto;
            z-index: 99;
            color: white !important;
          }
          .close:hover {
            color: $accent;
          }
        }
        .bdy {
          padding: 1em;

          .section {
            border: 1px solid #60606030;
            border-radius: 5px;
            padding: 10px;
            margin-bottom: 1em;

            h3 {
              font-size: small;
              color: $primary;
            }

            .item {
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;
              padding: 10px;
              margin-top: 10px;
              div {
                display: grid;
                grid-template-columns: 1fr auto;
              }

              p {
                font-size: small !important;
              }
              .fa-times {
                margin-left: auto;
                color: $accent;
                display: block;
              }
            }

            .ind {
              border: 1px solid #60606010;
              box-shadow: 1px 1px 5px #60606030;
              padding: 10px;
              margin-top: 5px;

              div {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 10px;
              }

              p {
                font-size: small !important;
              }
              .fa-times {
                margin-left: auto;
                color: $accent;
                display: block;
              }
            }

            .so_list {
              border-bottom: 1px solid #60606010;

              h2 {
                font-size: medium;
                margin-bottom: 2px;
                font-weight: 500;
              }
              h5 {
                font-size: small;
                margin-bottom: 2px;
                color: gray !important;
                font-weight: 200;
              }
            }

            .search {
              position: relative;

              .search_list {
                position: absolute;
                left: 0;
                right: 0;
                top: 74px;
                padding: 10px;
                border: 1px solid #60606030;
                border-radius: 5px;
                box-shadow: 1px 1px 5px #60606030;
                z-index: 9999;
                background-color: white;

                p {
                  font-size: small;
                  padding: 5px;
                  cursor: pointer;
                  margin-bottom: 3px;
                }
                p:hover {
                  color: $primarylight;
                }
              }
            }

            h4 {
              font-size: medium;
              margin-bottom: 10px;
              font-weight: 560;
            }

            hr {
              color: #60606010;
            }

            .input {
              margin: 10px 0 0 0;

              .pwd {
                position: relative;
                margin: 0px 0 10px 0;

                .fa-eye {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 5px;
                  color: $primarylight;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                }
              }

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }
              textarea {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }
              textarea:focus {
                border-bottom: 2px solid $primary;
              }
              input {
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
            .select {
              margin: 10px 0 0 0;

              label {
                display: block;
                margin: 10px 0 10px 0;
                font-weight: 400;
                font-size: small;
              }

              select {
                margin: 0px 0 10px 0;
                padding: 10px;
                width: 100%;
                background-color: #60606030;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 2px solid #60606070;
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
            }

            p {
              font-size: medium;
              margin: 0px 0 5px 0;

              b {
                color: #74465c;
                cursor: pointer;
              }
            }
          }

          h6 {
            font-size: medium;
            font-weight: 200;
            color: $accent;
            height: 32px;
            line-height: 32px;
            text-align: center;
          }
          button {
            display: block;
            margin: 1em auto 0 auto;
            padding: 10px 2em 10px 2em;
            border-radius: 5px;
            width: fit-content;
            background-color: $primary;
            color: white;
            border-top: none;
            border-right: none;
            border-left: none;
            box-shadow: 1px 1px 5px #60606030;
            cursor: pointer;
          }
          button:hover {
            background-color: rgba($color: $primary, $alpha: 0.8);
          }
        }
      }
    }

    .soitem {
      box-shadow: 1px 1px 5px #60606010;
      border-radius: 5px;
      border: 1px solid #60606010;
      padding: 1em;
      height: fit-content;
      font-size: small;
      margin-bottom: 1em;
      position: relative;

      .div2equal {
        gap: 0 !important;
      }

      .soindex {
        background-color: $primarylight;
        display: flex;
        align-items: center;
        color: white;
        font-size: large;
        border-radius: 10px;
        width: 60px;
        h2 {
          width: 100%;
          text-align: center;
          font-weight: 560;
        }
      }

      .status {
        position: absolute;
        right: -1em;
        top: 5px;
        background-color: $primarylight;
        border-radius: 0 5px 0 5px;
        z-index: 1;
        padding: 5px;
        color: white;
        font-size: small;
      }

      .sofficer {
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        box-shadow: 1px 1px 5px #60606030;
        .div4equal {
          display: grid;
          grid-template-columns: auto 1fr 1fr 1fr;
          gap: 10px;

          .index {
            background-color: $primarylight;
            display: flex;
            align-items: center;
            color: white;
            font-size: large;
            border-radius: 10px;
            h2 {
              width: 100%;
              text-align: center;
              font-weight: 500;
              font-size: medium;
              padding: 10px;
            }
          }
        }
      }
      h4 {
        font-weight: 500;
        margin-bottom: 5px;
      }
      .div3equal {
        margin-bottom: 8px;
      }
      hr {
        margin-bottom: 5px;
        color: #60606010;
      }
      .title {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        margin-bottom: 8px;

        img {
          width: 74px;
          max-height: 74px;
          height: fit-content;
          object-fit: contain;
          object-position: top;
        }
      }

      b {
        font-weight: 560;
      }

      p {
        margin-bottom: 2px;
        color: gray;
        font-size: x-small;
      }
    }

    .editpopup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      display: flex;
      align-items: center;
      background-color: rgba($color: black, $alpha: 0.1);

      .card {
        width: fit-content;
        min-width: 40%;
        margin: auto;
        background-color: white;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        position: relative;

        .fa-times {
          position: absolute;
          right: 20px;
          top: 12px;
          font-weight: bolder;
          // padding: 10px;
          cursor: pointer;
          color: white;
        }

        .fa-times:hover {
          color: $primarylighter;
        }

        h3 {
          color: white;
          text-align: center;
          font-size: medium;
          padding: 10px;
          background-color: $primary;
          border-radius: 10px 10px 0 0;
        }

        .content {
          padding: 0 1em;

          button {
            padding: 10px;
            background-color: $primary;
            color: white;
            cursor: pointer;
            border: none;
            border-radius: 5px;
            margin: 0 auto 1em auto;
            display: block;
          }

          button:hover {
            background-color: $primarylight;
          }
        }

        hr {
          color: #60606030;
        }
      }
    }

    .detailspopup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      display: flex;
      align-items: center;
      background-color: rgba($color: black, $alpha: 0.1);

      .card {
        width: fit-content;
        min-width: 50%;
        margin: auto;
        background-color: white;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        position: relative;

        .fa-times {
          position: absolute;
          right: 20px;
          top: 12px;
          font-weight: bolder;
          // padding: 10px;
          cursor: pointer;
          color: white;
        }
        .fa-times:hover {
          color: $primarylighter;
        }

        h3 {
          color: white;
          text-align: center;
          font-size: medium;
          padding: 10px;
          background-color: $primary;
          border-radius: 10px 10px 0 0;
        }

        hr {
          color: #60606030;
          margin-bottom: 1em;
        }

        .content {
          max-height: 500px;
          overflow: auto;
          max-width: 800px;

          .prow {
            display: grid;
            grid-template-columns: 1fr 4fr;
            border-bottom: 1px solid #60606030;
            padding: 0 1em;

            :first-child {
              background-color: #60606010 !important;
              padding: 2px !important;
              font-size: small !important;
            }
            p {
              padding: 2px !important;
              font-size: small !important;
            }
          }

          .section {
            padding: 0 1em;

            .prow {
              padding: 0;
            }
          }

          .input {
            margin: 10px 0 0 0;

            .pwd {
              position: relative;
              margin: 0px 0 10px 0;

              .fa-eye {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 5px;
                color: $primarylight;
                cursor: pointer;
                display: flex;
                align-items: center;
              }
            }

            label {
              display: block;
              margin: 10px 0 10px 0;
              font-weight: 400;
              font-size: small;
            }
            textarea {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }
            textarea:focus {
              border-bottom: 2px solid $primary;
            }
            input {
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }
            input:focus {
              border-bottom: 2px solid $primary;
            }
          }

          .select {
            font-size: small;
            margin: 10px 0 10px 0;

            label {
              display: block;
              margin: 10px 0 10px 0;
              font-weight: 400;
            }

            select {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }
            input:focus {
              border-bottom: 2px solid $primary;
            }
          }
          button {
            padding: 10px;
            background-color: $primary;
            color: white;
            cursor: pointer;
            border: none;
            border-radius: 5px;
            margin: 0 auto 1em auto;
            display: block;
          }
          button:hover {
            background-color: $primarylight;
          }
        }
      }
    }

    .dailypopup {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      display: flex;
      align-items: center;
      background-color: rgba($color: black, $alpha: 0.1);

      .card {
        width: fit-content;
        min-width: 50%;
        margin: auto;
        background-color: white;
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        position: relative;

        .fa-times {
          position: absolute;
          right: 20px;
          top: 12px;
          font-weight: bolder;
          // padding: 10px;
          cursor: pointer;
          color: white;
        }

        .fa-times:hover {
          color: $primarylighter;
        }

        h3 {
          color: white;
          text-align: center;
          font-size: medium;
          padding: 10px;
          background-color: $primary;
          border-radius: 10px 10px 0 0;
        }

        hr {
          color: #60606030;
          margin-bottom: 0.5em;
        }

        .content {
          max-height: 500px;
          overflow: auto;
          max-width: 800px;
          padding-bottom: 0.5em;

          .row {
            display: grid;
            grid-template-columns: 2em 0.7fr 0.5fr 1fr 1fr;
            border-bottom: 1px solid #60606030;
            padding: 0 0.5em;

            :first-child {
              background-color: #60606010 !important;
              padding: 2px !important;
              font-size: small !important;
            }

            p {
              padding: 2px !important;
              font-size: small !important;
            }
          }

          .section {
            padding: 0 1em;

            .prow {
              padding: 0;
            }
          }

          .input {
            margin: 10px 0 0 0;

            .pwd {
              position: relative;
              margin: 0px 0 10px 0;

              .fa-eye {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 5px;
                color: $primarylight;
                cursor: pointer;
                display: flex;
                align-items: center;
              }
            }

            label {
              display: block;
              margin: 10px 0 10px 0;
              font-weight: 400;
              font-size: small;
            }

            textarea {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            textarea:focus {
              border-bottom: 2px solid $primary;
            }

            input {
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            input:focus {
              border-bottom: 2px solid $primary;
            }
          }

          .select {
            font-size: small;
            margin: 10px 0 10px 0;

            label {
              display: block;
              margin: 10px 0 10px 0;
              font-weight: 400;
            }

            select {
              margin: 0px 0 10px 0;
              padding: 10px;
              width: 100%;
              background-color: #60606030;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            input:focus {
              border-bottom: 2px solid $primary;
            }
          }

          button {
            padding: 10px;
            background-color: $primary;
            color: white;
            cursor: pointer;
            border: none;
            border-radius: 5px;
            margin: auto;
            display: block;
          }

          button:hover {
            background-color: $primarylight;
          }
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .container {
      .topbar {
        grid-template-columns: 1fr;

        .top {
          grid-template-columns: repeat(6, 1fr);
          gap: 5px;
          // margin: auto;
          .fa-icon {
            width: 50px;
          }
        }
      }

      .dailywp {
        .dlist {
          grid-template-columns: 1fr;
        }
        .soitem {
          .update {
            right: 3px !important;
            flex-direction: column;
          }
        }
      }
    }
  }
}
