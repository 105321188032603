@import "./mediaquery";
@import "./variables";

.stats {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;

  .welcome {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    align-items: center;

    .refresh {
      width: 32px;
      height: 32px;
      color: $accent;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-radius: 5px;
    }
    .refresh:hover {
      color: $primary;
    }

    h1 {
      font-size: xx-large;
      font-weight: 560;
      margin-bottom: 10px;
      color: $primary;
    }

    input {
      padding: 2px 10px;
      border: 1px solid black;
      border-radius: 5px;
      font-size: large;
    }
  }

  .taskstats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .bar {
    display: grid;
    grid-template-columns: 10px 1fr;
    background-color: white;
    box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);

    div {
      width: 100%;
      height: 100%;
      background-color: $accent;
    }

    h2 {
      font-weight: 600;
      padding: 8px;
      font-size: medium;
      color: $primary;
    }
  }

  .divrow {
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .section {
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 1px 1px 3px #60606030;
    padding: 10px;

    h3 {
      text-align: center;
      margin-bottom: 10px;
      font-size: medium;
      font-weight: 500;
      color: $secondary;
    }

    .my-gauge {
      display: block;
      margin-top: auto;
    }

    .gauge {
      display: grid;
      grid-template-rows: auto;
      height: 100%;
      margin-top: auto;

      .gg {
        display: block;
        margin-top: auto;
      }
    }

    .single {
      display: grid;
      grid-template-columns: auto 1fr;
      place-content: center;
      gap: 10px;
      margin: auto;

      .ts {
        width: 44px;
        height: 44px;
        color: $accent;
        display: block;
        margin: auto;
      }

      h4 {
        font-size: x-large;
        font-weight: 900;
        color: $secondary;
        text-align: right;
        margin-bottom: 4px;
      }
      p {
        font-size: small;
        color: $secondary;
        text-align: right;
      }
    }

    .perf {
      display: grid;
      grid-template-rows: 1fr auto auto auto;
      gap: 10px;
      height: 100%;
      width: 100%;

      h2 {
        font-size: medium;
        font-weight: 520;
      }

      .hl {
        margin: auto;
        h4 {
          color: red;
          font-size: xx-large;
          font-weight: bolder;
          margin-bottom: 10px;
          text-align: center;
        }
        h5 {
          font-weight: 500;
          color: gray;
          text-align: center;
        }
      }

      .div1auto {
        width: 100%;
        display: grid;
        grid-template-columns: 10px 1fr auto;
        gap: 10px;
        padding: 8px;

        div {
          width: 100%;
          height: 100%;
        }

        p {
          font-size: medium;
        }
        h6 {
          font-size: large;
          font-weight: bolder;
          color: gray;
        }
      }
    }
  }

  .left {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1em;

    .outer {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1em;

      .ll {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 1em;
      }
    }
  }
  .right {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1em;
    .outer {
      height: 100%;
    }
  }

  .activities {
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
    width: 100%;
    overflow-x: auto;

    .bar {
      display: grid;
      grid-template-columns: 10px 1fr 1.5fr;
      align-items: end;
      background-color: white;
      box-shadow: 1px 1px 3px rgba(96, 96, 96, 0.1882352941);

      .l-bar {
        width: 100%;
        height: 100%;
        background-color: $accent;
      }

      h2 {
        font-weight: 600;
        padding: 8px;
        font-size: medium;
        color: $primary;
      }

      .div1auto {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 1em;
      }
      .dates {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
        margin-bottom: 5px;

        input {
          padding: 5px;
          margin: auto !important;
          width: 100%;
          outline: none;
          font-size: small;
          border-radius: 5px;
          background-color: #feece2;
          border: 1px solid rgba(96, 96, 96, 0.1882352941);
        }
        input:focus {
          border: 1px solid $secondary;
        }
      }
      .search {
        position: relative;
        margin-bottom: 5px;

        input {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          padding: 5px;
          margin: auto !important;
          width: 100%;
          border: 1px solid rgba(96, 96, 96, 0.1882352941);
          outline: none;
          font-size: small;
          border-radius: 5px;
          background-color: #feece2;
        }
        input:focus {
          border: 1px solid $secondary;
        }
        .fa-search {
          position: absolute;
          top: 0;
          bottom: 0;
          display: grid;
          place-content: center;
          right: 10px;
          height: 100%;
          color: $secondary;
        }
      }
    }

    .head,
    .row {
      display: grid;
      grid-template-columns: 2em 0.8fr 0.8fr 1.2fr 0.5fr 0.5fr 0.5fr 0.5fr;
      gap: 10px;
      padding: 10px;
      margin: 10px 0;
      box-shadow: 1px 1px 3px #60606030;
    }
    .head {
      background-color: green;
      color: white;
    }

    .row {
      background-color: white;
    }

    .row:hover {
      background-color: $bg;
    }
  }

  .pop-up {
    position: absolute;
    left: 0;
    right: 0;
    top: 1.5em;
    bottom: 0;

    .container {
      height: fit-content;
      width: 50vw;
      padding: 1em;
      background-color: #f6fafd;
      margin: auto;
      box-shadow: 2px 2px 10px rgba(96, 96, 96, 0.1882352941);
    }

    .top {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      margin: 10px 0 10px 0;

      div {
        display: flex;
        gap: 10px;
      }
      h4 {
        cursor: pointer;
        padding: 5px;
      }
      .active {
        background-color: $accent;
        color: white;
        border-radius: 5%;
      }
    }

    .details {
      display: grid;

      p {
        margin: 5px 0;
      }
    }

    .map-container {
      height: 200px;
      width: 100%;
    }
  }

  @include maxwidth(mobile) {
    h3 {
      font-size: small !important;
    }
    .taskstats {
      grid-template-columns: 1fr;

      .outer {
        grid-template-columns: 1fr !important;
      }
    }
    .activities {
      .div1auto {
        grid-template-columns: 1fr !important;
      }
      .dates {
        display: block !important;
      }
    }
  }

  @include maxwidth(tablet) {
    h3 {
      font-size: medium !important;
    }
    .taskstats {
      grid-template-columns: 1fr;
    }
  }
}
