@import "./mediaquery";
@import "./variables";

.projects {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 1em;
  position: relative;

  .prj {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;

    .item {
      background-color: white;
      border-radius: 5px;
      padding: 10px;

      .status {
        width: fit-content;
        margin: 0 -16px 0 auto;
        background-color: orange;
        p {
          font-size: x-small;
          padding: 5px;
          color: white;
        }
      }

      .title {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;

        h4 {
          background-color: $secondary;
          border-radius: 5px;
          color: white;
          font-size: xx-small;
          font-weight: 400;
          padding: 5px;
        }

        p {
          font-size: small;
          font-weight: 600;
        }
      }

      .desc {
        p {
          padding: 5px 0 5px 0;
          font-weight: 200;
          font-size: small;
        }
      }

      .objs {
        h4 {
          margin-top: 5px;
          font-size: small;
          color: $primary;
        }
        p {
          font-size: smaller;
          padding: 3px;
        }

        .objective {
          display: grid;
          grid-template-columns: 1fr 0.3fr;
          margin-bottom: 2px;

          .st {
            background-color: rgb(197, 196, 196);
            margin: auto;
            font-size: x-small;
            width: 100% !important;
            height: 100%;
            position: relative;

            p {
              z-index: 1;
              color: white !important;
            }

            span {
              background-color: rgb(0, 252, 0);
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              z-index: 1;
              padding: 2px;
            }
          }
        }
      }
    }

    @include maxwidth(mobile) {
      grid-template-columns: 1fr;
    }
    @include maxwidth(tablet) {
      grid-template-columns: 2fr;
    }
  }

  .popup {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.3);

    .cont {
      background-color: white;
      border: 1px solid #60606030;
      box-shadow: 2px 2px 10px #60606030;
      border-radius: 8px;
      padding: 1em;
      max-width: 85%;
      width: fit-content;
      margin: auto;
      height: fit-content;
      max-height: 80vh;
      overflow: auto;
    }

    p {
      font-size: small;
      font-weight: 400;
      margin: 2px 0 2px 0;
    }

    h3 {
      color: $primary;
      font-size: medium;
      margin: 2px 0 2px 0;
    }

    h4 {
      color: $primary;
      font-size: small;
      margin: 2px 0 2px 0;
    }

    .u_list {
      padding: 10px;
      border: 1px solid #60606030;
      box-shadow: 1px 1px 5px #60606030;
      border-radius: 5px;
    }

    .popobj {
      padding: 10px;
      border: 1px solid #60606030;
      box-shadow: 1px 1px 5px #60606030;
      border-radius: 5px;
      margin-bottom: 10px;
      .objective {
        display: grid;
        grid-template-columns: 1fr 0.3fr;
        margin-bottom: 2px;

        .st {
          background-color: rgb(197, 196, 196);
          margin: auto;
          font-size: x-small;
          width: 100% !important;
          height: 100%;
          position: relative;

          p {
            z-index: 1;
            color: white !important;
          }

          span {
            background-color: rgb(0, 252, 0);
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 1;
            padding: 2px;
          }
        }
      }
    }

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }

  .editdata {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.3);

    .cont {
      background-color: white;
      border: 1px solid #60606030;
      box-shadow: 2px 2px 10px #60606030;
      border-radius: 8px;
      padding: 1em;
      max-width: 80%;
      width: 100%;
      margin: auto;
      height: fit-content;
      max-height: 80vh;
      overflow: auto;
    }

    .dets {
      display: grid;
      grid-template-columns: 1fr auto;

      .fa {
        color: $accent;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $primary;
      }
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }

    hr {
      margin-bottom: 10px;
    }
  }

  .chart {
    background-color: white;
    height: 100%;
    min-height: 250px;
    width: 100%;
    border-radius: 8px;
    padding: 1em;
    position: relative;

    h3 {
      color: gray;
      font-size: medium;
      margin-bottom: 1em;
    }

    .save {
      position: absolute;
      right: 16px;
      top: 10px;
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;

      * {
        cursor: pointer;
      }
    }
  }

  .top {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    width: 100%;

    .tp_item {
      padding: 1em;
      background-color: white;
      border-radius: 8px;

      p {
        color: gray;
        font-size: small;
      }

      h1 {
        color: gray;
        font-weight: bolder;
        margin: 5px 0 5px 0;
        font-size: xx-large;
      }
    }
  }

  .pies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 1em 0 1em 0;
  }

  .middle {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  .bottom {
    height: fit-content !important;
    margin: 1em 0 1em 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  @include maxwidth(mobile) {
    .top {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .pies {
      grid-template-columns: 1fr !important;
    }

    .bottom {
      grid-template-columns: 1fr !important;
    }

    .middle {
      grid-template-columns: 1fr !important;
    }
  }
}
