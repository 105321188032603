@import "./mediaquery";
@import "./variables.scss";

.reports {
  height: 100%;
  width: 100%;
  padding: 1em;
  overflow-y: auto !important;
  overflow-x: hidden;

  .bars {
    display: grid;
    grid-template-columns: auto auto auto;
    width: fit-content;
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    p {
      background-color: white;
      padding: 5px 10px;
      color: $primary;
      cursor: pointer;
      font-size: small;
    }
    p:hover {
      background-color: $primarylight;
      color: white;
    }
    .active {
      background-color: $primarylight;
      color: white;
    }
  }

  .top {
    background-color: white;
    border-radius: 5px;
    padding: 1em;

    h3 {
      color: $primary;
      font-size: small;
      font-weight: 520;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    hr {
      color: #60606010;
      margin: 5px 0 10px 0;
    }

    .item {
      display: grid;
      grid-template-columns: 1fr auto auto auto;
      gap: 10px;
      padding: 5px;
      border: 1px solid #60606010;
      box-shadow: 1px 1px 5px #60606010;
      border-radius: 5px;
      margin-bottom: 5px;
      background-color: $bg1;

      .fas {
        color: $secondary;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .fas:hover {
        color: $primarylight;
      }

      .extent {
        transform: rotate(45deg);
      }

      * {
        font-size: small;
        align-items: center;
        display: flex;
      }
    }

    .add {
      display: grid;
      grid-template-columns: 1fr auto 1fr auto;
      gap: 10px;
      width: 100%;
      padding: 10px;
      background-color: $bg1;
      border-radius: 5px;
      margin: 10px 0 10px 0;

      .input {
        label {
          display: block;
          margin: 0px 0 5px 0;
          font-size: x-small !important;
        }

        textarea,
        input {
          margin: 0px 0 0 0;
          padding: 5px;
          width: 100%;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
          font-size: x-small;
        }
        textarea:focus,
        input:focus {
          border-bottom: 1px solid $primary;
        }
      }
      .select {
        label {
          display: block;
          margin: 0px 0 5px 0;
          font-size: x-small !important;
        }

        select {
          margin: 0px 0 5px 0;
          padding: 5px;
          width: 100%;
          font-size: x-small;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
        }
      }
      .btn {
        height: 24px;
        width: 24px;
        border-radius: 5px;
        cursor: pointer;
        background-color: $primarylight;
        display: flex;
        align-items: center;
        margin: auto;
        color: white;
        padding: 5px;
      }
      .btn:hover {
        background-color: $primary;
      }
    }

    .div3equal {
      padding: 1em;
    }

    .div1auto {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;

      .select {
        margin-top: auto;
        label {
          display: block;
          margin: 0px 0 5px 0;
          font-size: x-small !important;
        }

        select {
          margin: 0px 0 5px 0;
          padding: 5px;
          width: 100%;
          font-size: x-small;
          background-color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border: 1px solid #60606030;
          box-shadow: 1px 1px 5px #60606010;
        }
      }
      button {
        margin-top: auto !important;
        margin-bottom: 0 !important;
      }
    }

    button {
      height: fit-content;
      margin: 10px auto 10px auto;
      display: block;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      background-color: $primary;
      color: white;
      border: none;
    }
    button:hover {
      background-color: $primarylight;
    }
  }

  .list {
    border-radius: 5px;
    background-color: white;
    margin-top: 1em;
    overflow: hidden;

    .div1auto {
      background-color: $primarylight;
      padding: 0 1em 0 1em;

      h4 {
        color: white;
        font-size: small;
        font-weight: 520;
        display: flex;
        align-items: center;
      }
      .download {
        height: 32px;
        width: 32px;
        padding: 10px;
        color: white;
        margin: auto;
        border-radius: 5px;
        cursor: pointer;
      }
      .download:hover {
        color: $secondary;
      }
    }

    .data {
      min-height: 50vh;
      padding: 1em;

      .head {
        display: grid;
        grid-template-columns: 24px 1.5fr 1fr 1fr 1fr 1fr 1fr;
        background-color: $bg1;
        margin-bottom: 10px;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;

        h4 {
          font-weight: 500;
          font-size: small;
          padding: 8px;
          color: $primarylight;
        }
      }
      .item {
        display: grid;
        grid-template-columns: 24px 1.5fr 1fr 1fr 1fr 1fr 1fr;
        margin-bottom: 4px;
        box-shadow: 1px 1px 5px #60606010;
        border-radius: 5px;
        border: 1px solid #60606010;

        p {
          font-weight: 400;
          font-size: small;
          padding: 8px;
        }
      }
    }

    @include maxwidth(tablet) {
      .data {
        width: 100% !important;
        overflow-x: auto !important;
      }
    }
    @include maxwidth(mobile) {
      .data {
        width: 100% !important;
        overflow-x: auto !important;
      }
    }
  }

  .popup {
    background-color: rgba($color: #000000, $alpha: 0.1);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    .container {
      background-color: white;
      border-radius: 5px;
      overflow-y: auto;
      overflow-x: hidden;
      max-width: 70vw;
      width: 100%;
      margin: auto;
      max-height: 90vh;
      overflow-y: auto;

      .div1auto {
        background-color: $primarylight;
        padding: 10px 1em 10px 1em;

        h4 {
          color: white;
          font-size: small;
          font-weight: 520;
          display: flex;
          align-items: center;
        }
        .download {
          height: 32px;
          width: 32px;
          padding: 10px;
          color: white;
          margin: auto;
          border-radius: 5px;
          cursor: pointer;
        }
        .download:hover {
          color: $secondary;
        }
      }

      .data {
        min-height: 30vh;
        padding: 1em 0 1em 1em;

        .div2equal {
          gap: 0 !important;
        }

        .ditem {
          display: grid;
          grid-template-columns: 1fr 2fr;
          margin-right: 1em;
        }

        p {
          font-weight: 400;
          font-size: small;
          padding: 4px;
          border: 1px solid #60606010;
        }

        h4 {
          font-weight: 500;
          font-size: small;
          padding: 4px;
          color: $primarylight;
          background-color: $bg1;
          border: 1px solid #60606010;
        }
      }

      button {
        background-color: $primarylight;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        display: block;
        margin: 0 1em 10px auto;
      }
      button:hover {
        background-color: $primary;
      }
    }

    @include maxwidth(tablet) {
      .container {
        max-width: 95vw !important;
        width: 100%;
      }
    }
  }

  .table {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #60606030;
    margin-bottom: 1em;
    width: 100%;
    overflow-x: auto;
    h1 {
      color: $primary;
      font-size: medium;
      font-weight: 560;
      display: flex;
      align-items: center;
    }
    .filter {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: fit-content;
      gap: 1em;
      margin: 10px 0 10px auto;

      .download {
        height: 100%;
        width: 32px;
        cursor: pointer;
        padding: 5px;
        color: $primarylight;
        display: flex;
        align-items: center;
        background-color: $bg1;
        border-radius: 5px;
      }
      .download:hover {
        color: $primary;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;

      td,
      th {
        border: 1px solid black;
        padding: 5px;
      }

      thead {
        background-color: $primarylight;
        width: 100%;
        th {
          font-weight: 520;
          color: white;
          font-size: small;

          text-align: left;
        }
      }

      .cc {
        text-align: center;
      }

      td {
        font-size: small;
      }
    }
  }

  .charts {
    padding: 10px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #60606030;
    margin-bottom: 1em;

    h1 {
      color: $primary;
      font-size: medium;
      font-weight: 560;
      display: flex;
      align-items: center;
    }

    .select {
      height: fit-content;
      width: fit-content;
      margin-left: auto;
      color: $primarylight;
      background-color: $bg1;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606030;

      select {
        border: none;
        color: $primarylight;
      }

      select:focus {
        border-bottom: none !important;
      }
    }

    .filter {
      display: grid;
      grid-template-columns: repeat(8, auto);
      width: fit-content;
      gap: 1em;
      margin: 10px 0 10px auto;

      button {
        height: fit-content;
        background-color: $bg1;
        color: $primarylight;
        padding: 5px;
        cursor: pointer;
        display: block;
        margin: auto;
        border: none;
        border-radius: 5px;
        box-shadow: 1px 1px 5px #60606030;
      }

      button:hover {
        background-color: $primarylight;
        color: white;
      }

      .active {
        background-color: $primarylight;
        color: white;
      }

      label {
        color: $primary;
        margin-bottom: 5px;
        font-size: small;
      }

      input {
        padding: 8px;
        background-color: $primarylighter;
        border-radius: 5px;
        border: 1px solid $primarylight;
        color: white;
        outline: none;
        cursor: pointer;
      }
    }

    .chart {
      background-color: white;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      padding: 10px;
      position: relative;
      background-color: white;
      border-radius: 8px;
      box-shadow: 1px 1px 5px #60606030;
      padding: 1em;
      margin-bottom: 1em;

      .tp {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px !important;

        .download {
          height: 16px;
          width: 16px;
          color: $secondary;
          cursor: pointer;
        }

        .download:hover {
          color: $primarylight;
        }

        h3 {
          color: $primary;
          font-size: small;
          font-weight: 520;
          margin-bottom: 0;
        }

        p {
          color: gray;
          font-size: small;
        }

        hr {
          color: #60606010;
          margin-bottom: 10px;
        }
      }

      h3 {
        color: gray;
        font-size: medium;
        margin-bottom: 1em;
        font-weight: 600;
      }

      .save {
        position: absolute;
        right: 16px;
        top: 16px;
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;

        * {
          cursor: pointer;
        }
      }
    }
  }
}
