@import "./mediaquery";
@import "./variables";

.departments {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding: 1em;
  position: relative;

  p {
    font-size: medium !important;
  }

  .list {
    background-color: white;
    border-radius: 8px;
    padding: 1em;
    min-height: 100%;
    height: fit-content;

    .content {
      min-height: 60vh;

      .dept {
        box-shadow: 1px 1px 5px #60606020;
        border: 1px solid #60606020;
        border-radius: 5px;
        padding: 10px;

        h3 {
          font-weight: 500;
        }

        .units {
          padding: 10px;
          border: 1px solid #60606020;
          border-radius: 5px;
          margin: 5px 0 5px 0;
          .unit {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }
        }

        .classification {
          padding: 10px;
          border: 1px solid #60606020;
          border-radius: 5px;
          margin: 5px 0 5px 0;
          .unit {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
          }
        }
      }
    }

    h5 {
      font-size: medium;
      color: $primary;
      margin-bottom: 5px;
    }

    h6 {
      font-size: medium;
      color: $accent;
      margin: 5px;
      text-align: center;
    }

    .select {
      label {
        display: block;
        margin: 10px 0 10px 0;
        font-size: medium;
      }

      select {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      select:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .input {
      label {
        display: block;
        margin: 10px 0 10px 0;
        font-size: medium;
      }

      input {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      input:focus {
        border-bottom: 2px solid $primary;
      }
      textarea {
        margin: 0px 0 10px 0;
        padding: 10px;
        width: 100%;
        background-color: #60606030;
        border-top: none;
        border-right: none;
        border-left: none;
        outline: none;
        border-bottom: 2px solid #60606070;
      }
      textarea:focus {
        border-bottom: 2px solid $primary;
      }
    }

    .add {
      max-width: 200px;
      width: 100%;
      margin: 1em 0 1em auto;
    }
    button {
      display: block;
      margin: 1em auto 1em auto;
      padding: 8px 2em 8px 2em;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606030;
      width: fit-content;
      background-image: linear-gradient(
        $primarylighter 0%,
        $primarylight 50%,
        $primarylighter 100%
      );
      color: white;
      border: none;
      cursor: pointer;
    }
    button:hover {
      background-image: linear-gradient(
        $primarylight 0%,
        $primarylighter 50%,
        $primarylight 100%
      );
    }
    h3 {
      padding: 10px 0 10px 0;
      font-size: medium;
    }

    hr {
      margin-bottom: 10px;
    }

    .head,
    .row {
      display: grid;
      gap: 1px;
    }

    .row {
      cursor: pointer;
      margin-bottom: 2px;
    }
    .row:hover {
      box-shadow: 1px 1px 5px #60606020;
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    z-index: 999999;
    animation: zoom 1s ease;

    .pcontainer {
      background-color: white;
      border-radius: 4px;
      margin: 0 auto 0 auto;
      color: #000000;
      max-width: 70vw;
      max-height: 90vh;
      width: 100%;
      min-width: 50%;
      height: fit-content !important;
      overflow-y: auto;
      width: fit-content;
      position: relative;
      padding: 1em 2em;

      .fa-times {
        position: absolute;
        right: 10px;
        top: 10px;
        color: $accent;
        cursor: pointer;
      }

      .content {
        .checks {
          h4 {
            display: block;
            margin: 0px 0 10px 0;
            font-weight: 400;
          }

          .addnew {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 10px;
            margin: 10px 0 10px 0;

            input {
              margin: 0px 0 0 0;
              padding: 10px;
              width: 100%;
              background-color: #60606010;
              border-top: none;
              border-right: none;
              border-left: none;
              outline: none;
              border-bottom: 2px solid #60606070;
            }

            .add {
              margin: auto;
              height: 100%;
              padding: 10px;
              background-color: $primary;
              border-radius: 5px;
              color: white;
              cursor: pointer;
            }
            .add:hover {
              background-color: $accent;
            }
          }

          .s_list {
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 2px;
            margin: 6px 0 6px 0;
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 10px;

            .fa-times {
              display: block;
              padding: 6px;
              margin: auto;
              cursor: pointer;
              color: $primary;
            }
            .fa-times:hover {
              color: $accent;
            }

            p {
              font-size: small;
              padding: 10px;
            }
          }
        }

        button {
          display: block;
          margin: 1em auto 1em auto;
          padding: 8px 2em 8px 2em;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;
          width: fit-content;
          background-image: linear-gradient(
            $primarylighter 0%,
            $primarylight 50%,
            $primarylighter 100%
          );
          color: white;
          border: none;
          cursor: pointer;
        }
        button:hover {
          background-image: linear-gradient(
            $primarylight 0%,
            $primarylighter 50%,
            $primarylight 100%
          );
        }
        .input {
          label {
            display: block;
            margin: 10px 0 10px 0;
            font-size: medium;
          }

          input {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
          textarea {
            margin: 0px 0 10px 0;
            padding: 10px;
            width: 100%;
            background-color: #60606030;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 2px solid #60606070;
          }
          textarea:focus {
            border-bottom: 2px solid $primary;
          }
        }
      }
    }
  }

  @include maxwidth(mobile) {
    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;

        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
    }
  }
  @include maxwidth(tablet) {
    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;

        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }
    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: medium !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
    }
  }
}
